import React, { useEffect, useState } from 'react';
import iBuildSuspension from '../../types/buildSuspension/iBuildSuspension';
import BuildSuspensionEditPopBtn, {
  iBuildSuspensionEditPopBtn,
} from './BuildSuspensionEditPopBtn';
import BuildSuspensionService from '../../services/buildSuspension/BuildSuspensionService';
import Toaster from '../common/Toaster';
import { OP_GTE, OP_LTE, OP_OR } from '../../services/ServiceHelper';
import moment from 'moment';

type iLatestBuildSuspensionPopBtn = Omit<
  iBuildSuspensionEditPopBtn,
  'buildSuspension' | 'renderBtn'
> & {
  readOnly?: boolean;
  renderBtn?: (
    onClick: () => void,
    latestSuspension: iBuildSuspension,
  ) => React.ReactNode;
};
const LatestBuildSuspensionPopBtn = ({
  readOnly = false,
  buildId,
  renderBtn,
  ...props
}: iLatestBuildSuspensionPopBtn) => {
  const [latestSuspension, setLatestSuspension] =
    useState<iBuildSuspension | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    BuildSuspensionService.getAll({
      where: JSON.stringify({
        isActive: true,
        buildId,
        startDate: { [OP_LTE]: moment().toISOString() },
        [OP_OR]: [
          { endDate: null },
          { endDate: { [OP_GTE]: moment().subtract(1, 'day').toISOString() } },
        ],
      }),
      perPage: 1,
      include: 'Type,Reason',
      sort: 'startDate:DESC',
    })
      .then((resp) => {
        const result = resp.data || [];
        setLatestSuspension(result.length > 0 ? result[0] : null);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [buildId]);

  if (!latestSuspension || isLoading === true) {
    return null;
  }

  return (
    <BuildSuspensionEditPopBtn
      {...props}
      buildId={buildId}
      buildSuspension={latestSuspension}
      isDisabled={readOnly === true}
      modalProps={{
        title: 'Latest Suspension',
      }}
      renderBtn={
        renderBtn
          ? (onClick: () => void) => renderBtn(onClick, latestSuspension)
          : undefined
      }
    />
  );
};

export default LatestBuildSuspensionPopBtn;
