import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
`;

export const InlineFlex = styled.div`
  display: inline-flex;
`;

export default Flex;
