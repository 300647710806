import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsPrelimPanel = {
  build: iBuild;
};
const BuildDetailsPrelimPanel = ({ build }: iBuildDetailsPrelimPanel) => {
  return <Wrapper>Prelim: {build.id}</Wrapper>;
};

export default BuildDetailsPrelimPanel;
