import styled from 'styled-components';
import { iStateBeforeReload } from '../common/ComponentWithPreloadData';
import iBuild from '../../types/build/iBuild';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import { useState } from 'react';
import Tabs from '../frameWork/Tabs';
import Flex from '../frameWork/Flex';
import BuildDetailsTopSection from './BuildDetailsComponents/BuildDetailsTopSection';
import LeftAndRightPanel from '../layout/LeftAndRightPanel';
import BuildDetailsRightPanel from './BuildDetailsComponents/BuildDetailsRightPanel';
import BuildDetailsSummary from './BuildDetailsComponents/BuildDetailsSummary';
import BuildNoteList from '../buildNote/BuildNoteList';
import Tokens from '../frameWork/Tokens';
import BuildDetailsContractPanel from './BuildDetailsComponents/BuildDetailsContract/BuildDetailsContractPanel';
import BuildDetailsPrelimPanel from './BuildDetailsComponents/BuildDetailsPrelim/BuildDetailsPrelimPanel';
import BuildDetailsPlansPanel from './BuildDetailsComponents/BuildDetailsPlans/BuildDetailsPlansPanel';
import BuildDetailsOrdersPanel from './BuildDetailsComponents/BuildDetailsOrders/BuildDetailsOrdersPanel';
import BuildDetailsTrackingPanel from './BuildDetailsComponents/BuildDetailsTracking/BuildDetailsTrackingPanel';
import BuildDetailsDocumentsPanel from './BuildDetailsComponents/BuildDetailsDocuments/BuildDetailsDocumentsPanel';
import BuildDetailsVariationsPanel from './BuildDetailsComponents/BuildDetailsVariations/BuildDetailsVariationsPanel';
import BuildDetailsClaimsPanel from './BuildDetailsComponents/BuildDetailsClaims/BuildDetailsClaimsPanel';
import BuildDetailsPreHandOverPanel from './BuildDetailsComponents/BuildDetailsPreHandOver/BuildDetailsPreHandOverPanel';
import BuildDetailsPreConstructionSchedulePanel from './BuildDetailsComponents/BuildDetailsPreConstructionSchedule/BuildDetailsPreConstructionSchedulePanel';
import BuildDetailsConstructionSchedulePanel from './BuildDetailsComponents/BuildDetailsConstructionSchedule/BuildDetailsConstructionSchedulePanel';
import BuildDetailsVariancePanel from './BuildDetailsComponents/BuildDetailsVariance/BuildDetailsVariancePanel';
import BuildDetailsQuotesPanel from './BuildDetailsComponents/BuildDetailsQuotes/BuildDetailsQuotesPanel';
import BuildDetailsInvoicesPanel from './BuildDetailsComponents/BuildDetailsInvoices/BuildDetailsInvoicesPanel';
import LatestBuildSuspensionPopBtn from '../buildSuspension/LatestBuildSuspensionPopBtn';
import Lozenge from '../frameWork/Lozenge';

export type iBuildStyleDetailsPanel = iComponentWithPageHeader & {
  build: iBuild;
  isDisabled?: boolean;
  stateBeforeReload?: iStateBeforeReload;
  testId?: string;
  onSaved?: (saved: iBuild) => void;
  className?: string;
};
export const BUILD_DETAILS_TAB_SUMMARY = 'Summary';
export const BUILD_DETAILS_TAB_NOTES = 'Notes';
export const BUILD_DETAILS_TAB_CONTRACT = 'Contract';
export const BUILD_DETAILS_TAB_PRELIM = 'Prelim';
export const BUILD_DETAILS_TAB_PLANS = 'Plans';
export const BUILD_DETAILS_TAB_ORDERS = 'Orders';
export const BUILD_DETAILS_TAB_TRACKING = 'Tracking';
export const BUILD_DETAILS_TAB_DOCUMENTS = 'Documents';
export const BUILD_DETAILS_TAB_VARIATIONS = 'Variations';
export const BUILD_DETAILS_TAB_CLAIMS = 'Claims';
export const BUILD_DETAILS_TAB_PRE_HANDOVER = 'Pre-Handover';
export const BUILD_DETAILS_TAB_PRE_CONSTRUCTION_SCHEDULE =
  'Pre-Construction Schedule';
export const BUILD_DETAILS_TAB_CONSTRUCTION_SCHEDULE = 'Construction Schedule';
export const BUILD_DETAILS_TAB_VARIANCE = 'Variance';
export const BUILD_DETAILS_TAB_QUOTES = 'Quotes';
export const BUILD_DETAILS_TAB_INVOICES = 'Invoices';
const Wrapper = styled.div`
  .page-header {
    > div {
      margin-bottom: 0px;
      margin-top: ${Tokens('space.100', '16px')};
    }
    .create-btn-wrapper button {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
`;
const BuildDetailsPanel = ({
  headerProps,
  build,
  stateBeforeReload,
  testId,
  isDisabled,
  onSaved,
  className,
}: iBuildStyleDetailsPanel) => {
  const testIdStr = `buildDetails-${build.id}-${testId || ''}`;
  const [previousSate, setPreviousSate] = stateBeforeReload || [];
  const [selectedTabKey, setSelectedTabKey] = useState(
    previousSate?.selectedTabKey || BUILD_DETAILS_TAB_SUMMARY,
  );
  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <Flex className={'align-items-center gap-1'}>
            <div>{headerProps?.children}</div>
            <LatestBuildSuspensionPopBtn
              buildId={build.id}
              readOnly={true}
              renderBtn={(onClick, latestSuspension) => {
                return (
                  <Flex
                    className={
                      'cursor-pointer flex-column justify-content-center'
                    }
                    onClick={onClick}
                  >
                    <Lozenge appearance={'removed'}>
                      {latestSuspension.Type?.name}
                    </Lozenge>
                  </Flex>
                );
              }}
            />
          </Flex>
        ),
      }}
    >
      <Wrapper
        data-testid={testIdStr}
        className={`build-details-wrapper ${className || ''}`}
      >
        <LeftAndRightPanel
          rightPanel={<BuildDetailsRightPanel build={build} />}
        >
          <BuildDetailsTopSection build={build} />
          <Tabs
            testId={`${testIdStr}-tabs`}
            showQuickSelect={true}
            appearance={'tabs'}
            activeKey={selectedTabKey}
            onSelect={(key) => {
              setSelectedTabKey(key);
              setPreviousSate && setPreviousSate({ selectedTabKey: key });
            }}
            tabs={[
              {
                eventKey: BUILD_DETAILS_TAB_SUMMARY,
                title: BUILD_DETAILS_TAB_SUMMARY,
                tabContent: (
                  <BuildDetailsSummary
                    build={build}
                    onUpdated={(data) => onSaved && onSaved(data)}
                    isDisabled={isDisabled}
                  />
                ),
              },
              {
                eventKey: BUILD_DETAILS_TAB_NOTES,
                title: BUILD_DETAILS_TAB_NOTES,
                tabContent: (
                  <BuildNoteList
                    headerProps={{ children: <h5>Notes</h5> }}
                    buildId={build.id}
                  />
                ),
              },
              {
                eventKey: BUILD_DETAILS_TAB_CONTRACT,
                title: BUILD_DETAILS_TAB_CONTRACT,
                tabContent: <BuildDetailsContractPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_PRELIM,
                title: BUILD_DETAILS_TAB_PRELIM,
                tabContent: <BuildDetailsPrelimPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_PLANS,
                title: BUILD_DETAILS_TAB_PLANS,
                tabContent: <BuildDetailsPlansPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_ORDERS,
                title: BUILD_DETAILS_TAB_ORDERS,
                tabContent: <BuildDetailsOrdersPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_TRACKING,
                title: BUILD_DETAILS_TAB_TRACKING,
                tabContent: <BuildDetailsTrackingPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_DOCUMENTS,
                title: BUILD_DETAILS_TAB_DOCUMENTS,
                tabContent: <BuildDetailsDocumentsPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_VARIATIONS,
                title: BUILD_DETAILS_TAB_VARIATIONS,
                tabContent: <BuildDetailsVariationsPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_CLAIMS,
                title: BUILD_DETAILS_TAB_CLAIMS,
                tabContent: <BuildDetailsClaimsPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_PRE_HANDOVER,
                title: BUILD_DETAILS_TAB_PRE_HANDOVER,
                tabContent: <BuildDetailsPreHandOverPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_PRE_CONSTRUCTION_SCHEDULE,
                title: BUILD_DETAILS_TAB_PRE_CONSTRUCTION_SCHEDULE,
                tabContent: (
                  <BuildDetailsPreConstructionSchedulePanel build={build} />
                ),
              },
              {
                eventKey: BUILD_DETAILS_TAB_CONSTRUCTION_SCHEDULE,
                title: BUILD_DETAILS_TAB_CONSTRUCTION_SCHEDULE,
                tabContent: (
                  <BuildDetailsConstructionSchedulePanel build={build} />
                ),
              },
              {
                eventKey: BUILD_DETAILS_TAB_VARIANCE,
                title: BUILD_DETAILS_TAB_VARIANCE,
                tabContent: <BuildDetailsVariancePanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_QUOTES,
                title: BUILD_DETAILS_TAB_QUOTES,
                tabContent: <BuildDetailsQuotesPanel build={build} />,
              },
              {
                eventKey: BUILD_DETAILS_TAB_INVOICES,
                title: BUILD_DETAILS_TAB_INVOICES,
                tabContent: <BuildDetailsInvoicesPanel build={build} />,
              },
            ]}
          />
        </LeftAndRightPanel>
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default BuildDetailsPanel;
