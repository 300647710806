import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildStyleService from '../../services/build/BuildStyleService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import BuildStyleCreatePopupBtn from './BuildStyleCreatePopupBtn';
import iBuildStyle from '../../types/build/iBuildStyle';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import { URL_BUILD_STYLE_DETAILS } from '../../helpers/UrlMap';
import { Link, useNavigate } from 'react-router-dom';

type iBuildList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};
const BuildStyleList = ({ allowDelete, headerProps }: iBuildList) => {
  const navigate = useNavigate();
  const testIdStr = 'buildStyle-list';
  const { renderDataTable, renderDeleteBtn, onSetFilter } =
    useListCrudHook<iBuildStyle>({
      sort: `name:ASC`,
      getFn: (params) =>
        BuildStyleService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });
  const getColumns = (): iTableColumn<iBuildStyle>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        return (
          <Link to={URL_BUILD_STYLE_DETAILS.replace(':id', data.id)}>
            {data.name || ' '}
          </Link>
        );
      },
    },
    {
      key: 'description',
      header: 'Description',
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => `${data.description || ''}`,
    },
    {
      key: 'isCustom',
      header: 'Custom?',
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) =>
        DynamicTableHelper.getCheckedIcon(data.isCustomised),
    },
    {
      key: 'noOfBeds',
      header: 'Bedrooms',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => `${data.noOfBeds || ''}`,
    },
    {
      key: 'noOfBaths',
      header: 'Bathrooms',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => `${data.noOfBaths || ''}`,
    },
    {
      key: 'noOfCars',
      header: 'Car spaces',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => `${data.noOfCars || ''}`,
    },
    {
      key: 'floorArea',
      header: (
        <>
          Floor Area (
          <small>
            m<sup>2</sup>
          </small>
          )
        </>
      ),
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        const floorArea = `${data.floorArea || ''}`.trim();
        if (floorArea === '') {
          return '';
        }
        return (
          <>
            {floorArea} m<sup>2</sup>
          </>
        );
      },
    },
    {
      key: 'houseLength',
      header: 'House Length (m)',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        return `${data.houseLength || ''}`;
      },
    },
    {
      key: 'houseWidth',
      header: 'House Width (m)',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        return `${data.houseWidth || ''}`;
      },
    },
    {
      key: 'minBlockWidth',
      header: 'Min Block Width (m)',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        return `${data.minBlockWidth || ''}`;
      },
    },
    {
      key: 'minBlockDepth',
      header: 'Min Block Depth (m)',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStyle>) => {
        return `${data.minBlockDepth || ''}`;
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildStyle>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iBuildStyle>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      BuildStyleService.deactivate(data.id),
                    getDisplayName: (style) => style.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={
              <BuildStyleCreatePopupBtn
                onCreated={(created) =>
                  navigate(URL_BUILD_STYLE_DETAILS.replace(':id', created.id))
                }
                renderBtn={(onClick) =>
                  getCreateIconBtn({
                    testId: `${testIdStr}-create-btn`,
                    onClick,
                  })
                }
              />
            }
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search name ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : { name: { [OP_LIKE]: `%${searchText}%` } },
              );
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        selectiveColumnKey: SelectiveColKeys.BUILD_STYLE_LIST,
        columns: getColumns(),
        showPageSizer: true,
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildStyleList;
