import styled from 'styled-components';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Button, { ButtonProps, IconButton } from './Button';
import Flex from './Flex';
import Tokens from './Tokens';
import { LinkItem } from './Menu';
import Icons from './Icons';
import Popup from './Popup';
import TextField from './TextField';

type iTab = {
  eventKey: string;
  title: ReactNode;
  titleProps?: Omit<
    ButtonProps,
    'children' | 'onClick' | 'spacing' | 'appearance'
  >;
  tabContent: ReactNode;
};
const Wrapper = styled.div`
  .is-selected {
    button {
      background-color: ${Tokens('color.background.neutral.bold')};
      color: ${Tokens('color.text.inverse', '#ffffff')};
    }
  }
  .tab-panel {
    margin-top: ${Tokens('space.100', '16px')};
  }

  &.tabs {
    width: 100%;
    max-width: 100%;

    .tabs-top-row {
      display: flex; /* Flexbox for alignment */
      align-items: center;
      justify-content: space-between; /* Ensures space between title row and button */
      gap: 0.5rem;
      border-bottom: 1px solid
        ${Tokens('color.background.accent.gray.subtlest')};

      .tabs-title-row-wrapper {
        height: 32px;
        width: 100%;
        overflow-x: auto; /* Enable horizontal scrolling */
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        position: relative;

        /* Hide scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
        &::-webkit-scrollbar {
          display: none;
        }

        .tabs-title-row {
          position: absolute;
          top: 0;
          left: 0;
          // margin-top: ${Tokens('space.100', '8px')};
          // margin-bottom: ${Tokens('space.100', '8px')};
          white-space: nowrap; /* Prevent line breaks in tabs */
          button {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
            background-color: transparent;
            color: ${Tokens('color.text.accent.gray', '#eee')};
            &:hover {
              background-color: ${Tokens(
                'color.background.accent.gray.subtlest',
              )};
            }
          }

          .is-selected button {
            border-bottom: 3px ${Tokens('color.background.brand.bold')} solid;
          }
        }
      }
      .operation-btns {
        box-shadow: -7px 0 10px rgba(0, 0, 0, 0.1); /* Add left shadow */
        .scroll-btns {
          button {
            width: 24px;
          }
        }
      }
    }
  }
`;

const QuickSelectContentWrapper = styled.div`
  .item-list {
    overflow-y: auto;
    max-height: 15rem;
  }
`;
export type iTabs = {
  tabs: iTab[];
  activeKey?: string;
  isCompact?: boolean;
  className?: string;
  testId?: string;
  onSelect?: (key: string) => void;
  appearance?: 'pills' | 'tabs';
  showQuickSelect?: boolean;
};
const Tabs = ({
  tabs,
  isCompact,
  className,
  testId,
  activeKey,
  onSelect,
  appearance = 'pills',
  showQuickSelect = false,
}: iTabs) => {
  if (tabs.length <= 0) {
    return null;
  }

  const testIdStr = `${testId || ''}-tabs`;
  const [selectedEventKey, setSelectedEventKey] = useState(
    activeKey || tabs[0].eventKey,
  );
  const [isQuickSelectOpen, setIsQuickSelectOpen] = useState(false);
  const [quickSelectSearchTxt, setQuickSelectSearchTxt] = useState('');
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const tabRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 150; // Adjust the scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 150; // Adjust the scroll amount as needed
    }
  };

  const checkForOverflow = () => {
    if (scrollRef.current) {
      const isOverflowing =
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth;
      setShowScrollButtons(isOverflowing);
    }
  };

  useEffect(() => {
    // Check for overflow initially
    checkForOverflow();

    // Check for overflow on window resize
    const resizeObserver = new ResizeObserver(checkForOverflow);
    if (scrollRef.current) {
      resizeObserver.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        resizeObserver.unobserve(scrollRef.current);
      }
    };
  }, [tabs]);

  const scrollToTab = (eventKey: string) => {
    const tabRef = tabRefs.current[eventKey];
    if (tabRef) {
      tabRef.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  const getSelectedPanel = () => {
    const selectedTab = tabs.find((tab) => selectedEventKey === tab.eventKey);
    return (
      <div className={'tab-panel'} data-testid={`${testIdStr}-tab-panel`}>
        {selectedTab?.tabContent}
      </div>
    );
  };

  const getQuickSelectPanel = () => {
    if (!showQuickSelect) {
      return null;
    }
    return (
      <Popup
        testId={`${testId}-quick-select-panel`}
        isOpen={isQuickSelectOpen}
        placement={'bottom-end'}
        onClose={() => {
          setIsQuickSelectOpen(false);
          setQuickSelectSearchTxt('');
        }}
        content={() => (
          <QuickSelectContentWrapper>
            <TextField
              value={quickSelectSearchTxt}
              className={'quick-search-input'}
              placeholder={'Search tab name...'}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                setQuickSelectSearchTxt(event.target.value || '');
              }}
            />
            <div className={'item-list'}>
              {tabs
                .filter((tab) => {
                  const searchTxt = `${quickSelectSearchTxt || ''}`
                    .trim()
                    .toUpperCase();
                  if (searchTxt === '') {
                    return true;
                  }
                  return `${tab.title}`.toUpperCase().includes(searchTxt);
                })
                .map((tab) => {
                  return (
                    <LinkItem
                      key={tab.eventKey}
                      isSelected={selectedEventKey == tab.eventKey}
                      testId={`${testId}-${tab.eventKey}`}
                      onClick={() => {
                        setIsQuickSelectOpen(false);
                        setQuickSelectSearchTxt('');
                        setSelectedEventKey(tab.eventKey);
                        scrollToTab(tab.eventKey); // Scroll to the selected tab
                        onSelect && onSelect(tab.eventKey);
                      }}
                    >
                      {tab.title}
                    </LinkItem>
                  );
                })}
            </div>
          </QuickSelectContentWrapper>
        )}
        trigger={(triggerProps) => (
          <IconButton
            {...triggerProps}
            testId={`${testId}-quick-select-btn`}
            label={'tab-quick-select'}
            onClick={() => setIsQuickSelectOpen(!isQuickSelectOpen)}
            icon={Icons.SearchIcon}
            appearance={'subtle'}
          />
        )}
      />
    );
  };

  const getScrollBtns = () => {
    if (!showScrollButtons) {
      return null;
    }
    return (
      <Flex className={'scroll-btns'}>
        <IconButton
          testId={`${testId}-scroll-left-btn`}
          label={'scroll-left-btn`'}
          onClick={() => scrollLeft()}
          icon={Icons.ChevronLeftIcon}
          appearance={'subtle'}
        />
        <IconButton
          testId={`${testId}-scroll-right-btn`}
          label={'scroll-right-btn`'}
          onClick={() => scrollRight()}
          icon={Icons.ChevronRightIcon}
          appearance={'subtle'}
        />
      </Flex>
    );
  };

  const getOperationBtns = () => {
    const quickSelectDiv = getQuickSelectPanel();
    const scrollBtns = getScrollBtns();
    if (!quickSelectDiv && !scrollBtns) {
      return null;
    }

    return (
      <Flex className={'operation-btns'}>
        {scrollBtns}
        {quickSelectDiv}
      </Flex>
    );
  };

  return (
    <Wrapper
      className={`tabs-wrapper ${className || ''} ${appearance}`}
      data-testid={`${testIdStr}-wrapper`}
    >
      <Flex className={'tabs-top-row gap-05 align-items-end'}>
        <div
          className={'tabs-title-row-wrapper'}
          ref={scrollRef}
          data-testid={`${testIdStr}-title-row-wrapper`}
        >
          <Flex className={'tabs-title-row gap-05 align-items-end'}>
            {tabs.map((tab) => (
              <div
                key={tab.eventKey}
                className={`tab ${selectedEventKey == tab.eventKey ? 'is-selected' : ''}`}
              >
                <Button
                  {...tab.titleProps}
                  testId={`${testIdStr}-title-${tab.eventKey}`}
                  spacing={isCompact === true ? 'compact' : undefined}
                  isSelected={selectedEventKey == tab.eventKey}
                  onClick={() => {
                    setSelectedEventKey(tab.eventKey);
                    onSelect && onSelect(tab.eventKey);
                  }}
                  ref={(el) => {
                    if (el) {
                      tabRefs.current[tab.eventKey] = el;
                    }
                  }}
                >
                  {tab.title}
                </Button>
              </div>
            ))}
          </Flex>
        </div>
        {getOperationBtns()}
      </Flex>
      {getSelectedPanel()}
    </Wrapper>
  );
};

export default Tabs;
