import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsPlansPanel = {
  build: iBuild;
};
const BuildDetailsPlansPanel = ({ build }: iBuildDetailsPlansPanel) => {
  return <Wrapper>Plans: {build.id}</Wrapper>;
};

export default BuildDetailsPlansPanel;
