import { useEffect, useState, ReactNode, useRef, useCallback } from 'react';
import Toaster from './Toaster';
import PageInit from '../../pages/PageInit';
import EmptyState, { iEmptyState } from '../frameWork/EmptyState';
import Button from '../frameWork/Button';
import { useNavigate } from 'react-router-dom';
import { iConfigParams } from '../../services/AppService';

export type iStateBeforeReload = [
  stateBeforeReload: iConfigParams,
  setBeforeReload: (newState: iConfigParams) => void,
];
export type iComponentWithPreloadData<T> = {
  getDataFn: () => Promise<T>;
  children: (data: T, stateBeforeReload: iStateBeforeReload) => ReactNode;
  emptyStateProps?: Omit<iEmptyState, 'header'> & { header?: string };
  forceReload?: number;
  updatedModel?: T | null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
const ComponentWithPreloadData = <T extends any>({
  getDataFn,
  children,
  emptyStateProps,
  updatedModel,
  forceReload = 0,
}: iComponentWithPreloadData<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const stateBeforeReload = useRef<iConfigParams>({});
  const navigate = useNavigate();

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    getDataFn()
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setData(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [forceReload]);

  useEffect(() => {
    if (!updatedModel) {
      return;
    }
    setData(updatedModel);
  }, [updatedModel]);

  const setStateBeforeReload = useCallback(
    (newState: iConfigParams) => (stateBeforeReload.current = newState),
    [],
  );

  if (isLoading === true) {
    return <PageInit />;
  }

  if (!data) {
    return (
      <EmptyState
        header={`Ops, not found`}
        secondaryAction={
          <Button testId={'back-btn'} onClick={() => navigate(-1)}>
            back
          </Button>
        }
        {...emptyStateProps}
      />
    );
  }

  return (
    <>{children(data, [stateBeforeReload.current, setStateBeforeReload])}</>
  );
};

export default ComponentWithPreloadData;
