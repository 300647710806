import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iBuildArea from '../../types/build/iBuildArea';
import BuildAreaService from '../../services/build/BuildAreaService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import HouseAreaSelector from '../houseArea/HouseAreaSelector';
import { iOption } from '../frameWork/Select';
import StringHelper from '../../helpers/StringHelper';
import TextField from '../frameWork/TextField';
import { ReactNode } from 'react';
import styled from 'styled-components';
import InlineEdit from '../frameWork/InlineEdit';
import Tokens from '../frameWork/Tokens';
import Flex from '../frameWork/Flex';
import Toaster, { TOAST_TYPE_ERROR } from '../common/Toaster';

const TabWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  > * {
    margin-top: 0px;
    margin-bottom: ${Tokens('space.100', '8px')};
  }
  form {
    margin-top: 0px;
    > * {
      margin-top: 0px;
    }
    label {
      width: 100%;
    }
    [data-testid^='delete-icon-btn-'] {
      visibility: hidden;
      height: 1rem;
      width: 1rem;
      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    &:hover {
      [data-testid^='delete-icon-btn-'] {
        visibility: visible;
      }
    }
  }
`;

type iBuildAreaList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
  entityName: string;
  entityId: string;
  pageSize?: number;
  isDisabled?: boolean;
  showCreatedAndUpdated?: boolean;
  appearance?: 'table' | 'tabs';
  preTabs?: ReactNode;
  className?: string;
  onSaved?: (saved: iBuildArea, isCreated: boolean) => void;
  onDeleted?: () => void;
};
const BuildAreaList = ({
  headerProps,
  allowDelete,
  allowCreate,
  entityName,
  entityId,
  pageSize,
  preTabs,
  className,
  isDisabled = false,
  showCreatedAndUpdated = true,
  appearance = 'table',
  onSaved,
  onDeleted,
}: iBuildAreaList) => {
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    state,
    onRefresh,
  } = useListCrudHook<iBuildArea>({
    sort: `createdAt:DESC`,
    perPage: pageSize,
    getFn: (params) =>
      BuildAreaService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityName,
          entityId,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,HouseArea',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (note?: iBuildArea) => {
    return renderEntityEditPopBtn<iBuildArea>({
      isDisabled,
      editingEntity: note,
      entityName: 'Area',
      onSaved,
      createFn: (data) =>
        BuildAreaService.create({
          ...data,
          entityName,
          entityId,
        }),
      updateFn: (id, data) => BuildAreaService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`HouseArea-name-${entity.id}`}
        >
          {entity.HouseArea?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => {
        const existingHouseAreaIds = (state.data.data || []).map(
          (area) => area.houseAreaId,
        );
        return [
          {
            fieldName: 'houseAreaId',
            label: 'Area',
            isDisabled,
            isRequired: true,
            value: entity?.houseAreaId || '',
            testId: 'BuildArea-houseAreaId',
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <HouseAreaSelector
                  {...props}
                  {...errorProps}
                  isOptionDisabled={(option: iOption) =>
                    existingHouseAreaIds.indexOf(`${option?.value || ''}`) >= 0
                  }
                  onChange={(selected: iOption) => {
                    useAsForm.onFieldChange(props.fieldName, selected.value);
                  }}
                />
              );
            },
          },
          {
            fieldName: 'size',
            label: 'Size (m2)',
            isDisabled,
            value: entity?.size || '',
            testId: 'BuildArea-size',
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <TextField
                  {...props}
                  {...errorProps}
                  elemAfterInput={
                    <div style={{ padding: '0 5px' }}>
                      m<sup>2</sup>
                    </div>
                  }
                  onChange={(event) => {
                    useAsForm.onFieldChange(
                      props.fieldName,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      event.target.value || '',
                    );
                  }}
                />
              );
            },
            isValid: (value) => {
              const val = `${value || ''}`.trim();
              if (val === '') {
                return { isValid: true, errMessages: [] };
              }
              if (!StringHelper.isNumeric(val)) {
                return {
                  isValid: false,
                  errMessages: ['Size needs to be a number'],
                };
              }
              return { isValid: true, errMessages: [] };
            },
          },
        ];
      },
    });
  };

  const getDeleteBtn = (data: iBuildArea) => {
    if (allowDelete !== true || isDisabled === true) {
      return null;
    }
    return renderDeleteBtn({
      deletingModel: data,
      deleteFnc: async () => BuildAreaService.deactivate(data.id),
      getDisplayName: (area) => area.HouseArea?.name || '',
      onDeleted,
    });
  };

  const getColumns = (): iTableColumn<iBuildArea>[] => [
    {
      key: 'houseArea',
      header: 'Area',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildArea>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'size',
      header: (
        <>
          Size ( m<sup>2</sup> )
        </>
      ),
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildArea>) => `${data.size || ''}`,
    },
    ...(showCreatedAndUpdated === true
      ? DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildArea>()
      : []),
    ...(allowDelete !== true || isDisabled === true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iBuildArea>) => {
              return <div className={'text-right'}>{getDeleteBtn(data)}</div>;
            },
          },
        ]),
  ];

  const renderList = () => {
    const classStr = `buildArea-list-wrapper ${appearance} ${className || ''}`;
    if (appearance === 'table') {
      return renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'BuildArea-list',
          className: classStr,
        },
      });
    }
    const tabs = (state.data.data || []).sort((a, b) =>
      `${a.HouseArea?.name || ''}`.trim().toUpperCase() >
      `${b.HouseArea?.name || ''}`.trim().toUpperCase()
        ? 1
        : -1,
    );
    return (
      <TabWrapper className={classStr}>
        {preTabs}
        {tabs.map((item) => {
          return (
            <div key={item.id} className={'size-div'}>
              <InlineEdit
                value={`${item.size || ''}`}
                isDisabled={isDisabled}
                readViewFitContainerWidth
                placeHolder={`Size in m2 `}
                onConfirm={(newValue) => {
                  const newVal = `${newValue || ''}`.trim();
                  if (newVal === '') {
                    return;
                  }
                  if (!StringHelper.isNumeric(newVal)) {
                    Toaster.showToast(
                      'Size needs to be a number',
                      TOAST_TYPE_ERROR,
                    );
                    return;
                  }
                  BuildAreaService.update(item.id, { size: newVal }).then(
                    (resp) => {
                      onRefresh();
                      onSaved && onSaved(resp, false);
                    },
                  );
                }}
                label={
                  <Flex className={'justify-content-between'}>
                    <span>
                      {item.HouseArea?.name || ''}{' '}
                      <small>
                        (m<sup>2</sup>)
                      </small>
                    </span>
                    {getDeleteBtn(item)}
                  </Flex>
                }
              />
            </div>
          );
        })}
      </TabWrapper>
    );
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        ...(allowCreate === true && isDisabled !== true
          ? {
              children: (
                <PageTitleWithCreateBtn
                  createBtn={getEditBtn()}
                  title={headerProps?.children}
                />
              ),
            }
          : {}),
      }}
    >
      {renderList()}
    </ComponentWithPageHeader>
  );
};

export default BuildAreaList;
