import iBaseType from '../iBaseType';

export const ASSET_TYPE_HOUSE_STYLE_PHOTOS = 'HOUSE_STYLE_PHOTOS';
type iAsset = iBaseType & {
  type: string;
  fileName: string;
  mimeType: string;
  fileSize?: number | null;
  comments?: string | null;
  url?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalObj?: any | null;
  externalId?: string | null;
  isPublic: boolean;
};

export default iAsset;
