import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsOrdersPanel = {
  build: iBuild;
};
const BuildDetailsOrdersPanel = ({ build }: iBuildDetailsOrdersPanel) => {
  return <Wrapper>Orders: {build.id}</Wrapper>;
};

export default BuildDetailsOrdersPanel;
