import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { URL_CONTACT_COMPANY_DETAILS } from '../../../helpers/UrlMap';
import iBuild from '../../../types/build/iBuild';
import Tokens from '../../frameWork/Tokens';
import UserHelper from '../../../helpers/UserHelper';
import FormField from '../../frameWork/FormField';

type iBuildDetailsTopSection = {
  build: iBuild;
};
const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.100', '1rem')};
  flex-wrap: wrap;
  > div {
    min-width: 9rem;
  }
  margin-bottom: ${Tokens('space.200', '1rem')};
`;
const BuildDetailsTopSection = ({ build }: iBuildDetailsTopSection) => {
  const clientEmail = `${build.Client?.email || ''}`.trim();
  const clientPhone = `${build.Client?.contactNumber || ''}`.trim();
  return (
    <Wrapper className={'details-top'}>
      {[
        {
          label: 'Client',
          value: () => (
            <Link
              to={URL_CONTACT_COMPANY_DETAILS.replace(':id', build.clientId)}
            >
              {build.Client?.name}
            </Link>
          ),
        },
        {
          label: 'Client Email',
          value: () => (
            <a href={`mailto:${clientEmail}`}>{clientEmail || ''}</a>
          ),
        },
        {
          label: 'Client Phone',
          value: () => <a href={`tel:${clientPhone}`}>{clientPhone || ''}</a>,
        },
        {
          label: 'Sales Person',
          value: () => UserHelper.getFullName(build.SalesPerson),
        },
        {
          label: 'Sales Manager',
          value: () => UserHelper.getFullName(build.SalesManager),
        },
        {
          label: 'Start Supervisor',
          value: () => UserHelper.getFullName(build.Supervisor1),
        },
        {
          label: 'End Supervisor',
          value: () => UserHelper.getFullName(build.Supervisor2),
        },
      ].map((item) => {
        return (
          <FormField
            label={item.label}
            key={item.label}
            render={() => <div>{item.value()}</div>}
          />
        );
      })}
    </Wrapper>
  );
};

export default BuildDetailsTopSection;
