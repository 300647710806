import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppHelper from '../../helpers/AppHelper';

export type AppState = {
  isProd?: boolean;
  locale: string;
};
/**
 * Initial State
 */
const initialState: AppState = {
  locale: AppHelper.getDefaultLocale(),
};

/**
 * Actions
 */
const actions = {
  setIsProd: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'isProd'>>,
  ) => ({
    ...initialState,
    ...state,
    isProd: action.payload.isProd,
  }),
  setLocale: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'locale'>>,
  ) => ({
    ...initialState,
    ...state,
    locale: action.payload.locale || initialState.locale,
  }),
};
/**
 * Slice
 */
const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { setIsProd, setLocale } = AppSlice.actions;
/**
 * reducer
 */
export default AppSlice.reducer;
