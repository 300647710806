import iBuild from '../../../types/build/iBuild';
import styled from 'styled-components';
import SectionDiv from '../../frameWork/SectionDiv';
import Flex from '../../frameWork/Flex';
import { DatePicker, formatDate } from '../../frameWork/DateTimePicker';
import BuildService from '../../../services/build/BuildService';
import { useState } from 'react';
import Toaster from '../../common/Toaster';
import Toggle from '../../frameWork/Toggle';
import UserSelector from '../../user/UserSelector';
import { iOption } from '../../frameWork/Select';
import GarageSizeSelector from '../../garageSize/GarageSizeSelector';
import HouseStoreySelector from '../../houseStorey/HouseStoreySelector';
import BuildSuspensionList from '../../buildSuspension/BuildSuspensionList';
import BuildAreaList from '../../buildArea/BuildAreaList';
import EntityNames from '../../../helpers/EntityNames';

const Wrapper = styled.div``;

type iBuildDetailsSummary = {
  build: iBuild;
  isDisabled?: boolean;
  onUpdated: (updated: iBuild) => void;
  className?: string;
};

const BuildDetailsSummary = ({
  build,
  onUpdated,
  className,
  isDisabled,
}: iBuildDetailsSummary) => {
  const testId = 'build-details-summary';
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (fieldName: string, newValue: string | boolean) => {
    setIsSaving(true);
    BuildService.update(build.id, {
      [fieldName]: newValue,
    })
      .then((resp) => {
        onUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Wrapper
      className={`build-details-summary ${className || ''}`}
      data-testid={testId}
    >
      <SectionDiv className={'flex gap-1 flex-wrap no-margin'}>
        <Flex className={'align-items-end gap-1'}>
          <DatePicker
            appearance={'subtle'}
            isDisabled={isSaving || isDisabled}
            label={'Settlement Date'}
            value={formatDate(build.settlementDate)}
            onChange={(value: string) => handleUpdate('settlementDate', value)}
          />
          <div>
            <Toggle
              isDisabled={isSaving || isDisabled}
              label={'confirmed?'}
              isChecked={build.isSettlementDateConfirmed === true}
              onChange={() =>
                handleUpdate(
                  'isSettlementDateConfirmed',
                  !build.isSettlementDateConfirmed,
                )
              }
            />
          </div>
        </Flex>
        {[
          { fieldName: 'supervisor1Id', label: 'Start Supervisor' },
          { fieldName: 'supervisor2Id', label: 'End Supervisor' },
          { fieldName: 'salesPersonId', label: 'Sales Person' },
          { fieldName: 'salesManagerId', label: 'Sales Manager' },
        ].map((item) => {
          return (
            <div key={item.fieldName}>
              <UserSelector
                appearance={'subtle'}
                value={
                  item.fieldName in build
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      build[item.fieldName]
                    : undefined
                }
                label={item.label}
                onChange={(selected: iOption) =>
                  handleUpdate(item.fieldName, selected?.data.id)
                }
              />
            </div>
          );
        })}
      </SectionDiv>
      <SectionDiv>
        <BuildAreaList
          appearance={'tabs'}
          allowCreate
          allowDelete
          headerProps={{ children: <h4>Areas</h4> }}
          entityName={EntityNames.Build}
          entityId={build.id}
          preTabs={
            <>
              <GarageSizeSelector
                appearance={'subtle'}
                label={'Garage Size'}
                value={build.garageSizeId}
                onChange={(selected: iOption) =>
                  handleUpdate('garageSizeId', selected?.data.id)
                }
              />
              <HouseStoreySelector
                appearance={'subtle'}
                label={'Storey'}
                value={build.storeyId}
                onChange={(selected: iOption) =>
                  handleUpdate('storeyId', selected?.data.id)
                }
              />
            </>
          }
        />
      </SectionDiv>

      <SectionDiv>
        <BuildSuspensionList
          buildId={build.id}
          allowDelete
          onSaved={() => onUpdated && onUpdated(build)}
          onDeleted={() => onUpdated && onUpdated(build)}
          headerProps={{
            children: <h4>Suspension / On Hold</h4>,
          }}
        />
      </SectionDiv>
    </Wrapper>
  );
};

export default BuildDetailsSummary;
