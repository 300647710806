import styled from 'styled-components';
import iBuild from '../../../types/build/iBuild';
import { formatDate } from '../../frameWork/DateTimePicker';
import Flex from '../../frameWork/Flex';
import FormField from '../../frameWork/FormField';
import moment from 'moment';
import { RIGHT_PANEL_WIDTH } from '../../layout/LeftAndRightPanel';
import MathHelper from '../../../helpers/MathHelper';
import Lozenge from '../../frameWork/Lozenge';
import { Label } from '../../frameWork/Form';
import Tokens from '../../frameWork/Tokens';

const Wrapper = styled.div`
  .style-name {
    padding: ${Tokens('space.100')};
  }
  form,
  form > div {
    margin-top: 0;
  }

  .row {
    flex-wrap: wrap;

    > div {
      width: ${MathHelper.div(MathHelper.sub(RIGHT_PANEL_WIDTH, 50), 3)}px;
    }
  }
`;
type iBuildRightPanel = {
  build: iBuild;
};
const BuildRightPanel = ({ build }: iBuildRightPanel) => {
  const formatedContractDate = formatDate(build.contractDate);
  const formatedSettlementDate = formatDate(build.settlementDate);
  const formatedActualStartDate = formatDate(build.actualStartDate);
  const formatedEstFinishDate = formatDate(
    build.estFinishDateFromActualStartDate,
  );
  return (
    <Wrapper>
      <div>
        <Label htmlFor={''}>Style</Label>
        <div className={'style-name'}>
          {build?.Style?.name || ''}
          {build?.BaseOffStyle && (
            <>
              {' '}
              <Lozenge appearance={'success'}>
                {build?.BaseOffStyle?.name || ''}
              </Lozenge>
            </>
          )}
        </div>
      </div>
      <Flex className={'gap-1 row'}>
        <FormField
          label={'Contract Date'}
          render={() => (
            <div>
              {formatedContractDate &&
                moment(formatedContractDate).format('ll')}
            </div>
          )}
        />
        <FormField label={'Contract Days'} render={() => <div></div>} />
      </Flex>
      <Flex className={'gap-1 row'}>
        <FormField
          label={'Actual Start Date'}
          render={() => (
            <div>
              {formatedActualStartDate &&
                moment(formatedActualStartDate).format('ll')}
            </div>
          )}
        />
        <FormField
          label={'Est. Finish Date'}
          render={() => (
            <div>
              {formatedEstFinishDate &&
                moment(formatedEstFinishDate).format('ll')}
            </div>
          )}
        />
        <FormField
          label={'Days Left'}
          render={() => (
            <div>
              {formatedEstFinishDate &&
                moment(formatedEstFinishDate).diff(moment(), 'days')}
            </div>
          )}
        />
      </Flex>
      <Flex className={'gap-1'}>
        <FormField
          label={'Settlement Date'}
          render={() => (
            <Flex className={'gap-1 align-items-center'}>
              <div>
                {formatedSettlementDate &&
                  moment(formatedSettlementDate).format('ll')}
              </div>
              {build.isSettlementDateConfirmed && (
                <Lozenge appearance={'success'}>is confirmed</Lozenge>
              )}
            </Flex>
          )}
        />
      </Flex>
    </Wrapper>
  );
};

export default BuildRightPanel;
