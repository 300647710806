import { getFooterWithBtns, iPopupModal } from '../common/PopupModal';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import React, { useEffect, useState } from 'react';
import iBuildSuspension from '../../types/buildSuspension/iBuildSuspension';
import { getErrorProps, iErrorMap } from '../form/FormError';
import { iConfigParams } from '../../services/AppService';
import Icons from '../frameWork/Icons';
import { iOption } from '../frameWork/Select';
import BuildSuspensionTypeSelector from './BuildSuspensionTypeSelector';
import BuildSuspensionService from '../../services/buildSuspension/BuildSuspensionService';
import Toaster from '../common/Toaster';
import TextArea from '../frameWork/TextArea';
import BuildSuspensionReasonSelector from './BuildSuspensionReasonSelector';
import Flex from '../../components/frameWork/Flex';
import { DatePicker } from '../../components/frameWork/DateTimePicker';
import moment from 'moment';

export type iBuildSuspensionEditPopBtn = Omit<
  iPopupBtn,
  'titleId' | 'modalProps'
> & {
  buildSuspension?: iBuildSuspension;
  buildId: string;
  modalProps?: Omit<iPopupModal, 'titleId' | 'isOpen' | 'children'>;
  onSaved?: (saved: iBuildSuspension, isCreated: boolean) => void;
};
const BuildSuspensionEditPopBtn = ({
  buildSuspension,
  buildId,
  onSaved,
  modalProps,
  isDisabled,
  ...props
}: iBuildSuspensionEditPopBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const buildSuspensionId = `${buildSuspension?.id || ''}`.trim();

  useEffect(() => {
    if (buildSuspensionId === '') {
      setEditingData({});
      return;
    }

    setEditingData({ ...buildSuspension });
  }, [buildSuspension, buildSuspensionId]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setIsSaving(false);
    setErrorMap({});
    // setEditingData({});
    setModelShowing(false);
  };

  const onFieldChange = (fieldName: string, newValue: string | null) => {
    setEditingData({ ...editingData, [fieldName]: newValue });
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    [
      {
        fieldName: 'typeId',
        errorMsg: 'Type is required',
      },
      {
        fieldName: 'reasonId',
        errorMsg: 'Reason is required',
      },
      {
        fieldName: 'startDate',
        errorMsg: 'Start Date is required',
      },
    ].forEach((item) => {
      const content =
        `${item.fieldName in editingData ? editingData[item.fieldName] : ''}`.trim();
      if (content === '') {
        errors[item.fieldName] = [item.errorMsg];
      }
    });

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (preSave() !== true) {
      return;
    }

    setIsSaving(true);
    const data = {
      ...editingData,
      buildId,
    };
    const savingFn = () =>
      buildSuspensionId === ''
        ? BuildSuspensionService.create(data)
        : BuildSuspensionService.update(buildSuspensionId, data);
    savingFn()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, buildSuspensionId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showToast(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'BuildSuspensionEditPopBtn'}
      modalProps={(setModelShowing) => ({
        width: '760px',
        title:
          buildSuspensionId === '' ? 'Create Suspension' : 'Update Suspension',
        shouldScrollInViewport: true,
        ...modalProps,
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <>
            <BuildSuspensionTypeSelector
              label={'Type'}
              testId={'typeId'}
              isRequired
              isDisabled={isDisabled}
              value={editingData.typeId}
              onChange={(selected: iOption) =>
                onFieldChange('typeId', `${selected.value || ''}`)
              }
              {...getErrorProps({ error: errorMap, fieldName: 'typeId' })}
            />

            <BuildSuspensionReasonSelector
              label={'Reason'}
              testId={'reasonId'}
              isRequired
              isDisabled={isDisabled}
              value={editingData.reasonId}
              onChange={(selected: iOption) =>
                onFieldChange('reasonId', `${selected.value || ''}`)
              }
              {...getErrorProps({ error: errorMap, fieldName: 'reasonId' })}
            />
            <Flex className={'flex-wrap gap-1'}>
              <DatePicker
                isRequired
                testId={'startDate'}
                value={editingData.startDate}
                label={'Start Date'}
                isDisabled={isDisabled}
                {...getErrorProps({ error: errorMap, fieldName: 'startDate' })}
                onChange={(selected) => {
                  onFieldChange(
                    'startDate',
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
              <DatePicker
                label={'End Date'}
                testId={'endDate'}
                isDisabled={isDisabled}
                value={editingData.endDate}
                onChange={(selected) => {
                  onFieldChange(
                    'endDate',
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
            </Flex>
            <TextArea
              label={'Details'}
              testId={'details'}
              isDisabled={isDisabled}
              value={editingData.details}
              {...getErrorProps({ error: errorMap, fieldName: 'details' })}
              onChange={(event) => {
                onFieldChange('details', `${event.target.value || ''}`);
              }}
            />
          </>
        ),
      })}
    />
  );
};

export default BuildSuspensionEditPopBtn;
