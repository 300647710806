import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';
import { Content, LeftSidebar } from '../../../frameWork/PageLayout';
import { useState } from 'react';
import SideNavigation, {
  LinkItem,
  NavigationContent,
  Section,
} from '../../../frameWork/SideNavigation';
import Tokens from '../../../frameWork/Tokens';
import BuildDetailsContractHelper, {
  BuildDetailsContractKeys,
} from './BuildDetailsContractHelper';

const MIN_LEFT_SIDE_BAR_WIDTH = 150;
const Wrapper = styled.div`
  .content {
    margin-left: ${Tokens('space.150', '16px')};
  }
`;

type iBuildDetailsContractPanel = {
  build: iBuild;
  selectedMenuKey?: string;
  testId?: string;
};
const BuildDetailsContractPanel = ({
  testId,
  selectedMenuKey,
}: iBuildDetailsContractPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-contract`;
  const [selectedKey, setSelectedKey] = useState(
    selectedMenuKey || BuildDetailsContractKeys.OVERVIEW,
  );

  const getStyle = () => {
    return <style></style>;
  };

  const getSideBar = () => {
    return (
      <LeftSidebar
        isFixed={false}
        testId={'buildDetails-contract-left'}
        width={MIN_LEFT_SIDE_BAR_WIDTH}
      >
        <SideNavigation
          label={'buildDetails-contract-leftMenu'}
          testId={`${testIdStr}-sideNavigation`}
        >
          <NavigationContent
            testId="navigation-content"
            className="nav-content"
          >
            <Section isList>
              {Object.entries(
                BuildDetailsContractHelper.buildDetailsContractMenuItemsMap,
              ).map(([key, item]) => (
                <LinkItem
                  key={key}
                  testId={key}
                  iconBefore={item.icon}
                  isSelected={key === selectedKey}
                  onClick={() => setSelectedKey(key)}
                >
                  {item.name}
                </LinkItem>
              ))}
            </Section>
          </NavigationContent>
        </SideNavigation>
      </LeftSidebar>
    );
  };

  return (
    <Wrapper>
      {getStyle()}
      <Content>
        {getSideBar()}
        <div className={'content'}>
          {selectedKey in
          BuildDetailsContractHelper.buildDetailsContractMenuItemsMap
            ? BuildDetailsContractHelper.buildDetailsContractMenuItemsMap[
                selectedKey
              ].component
            : null}
        </div>
      </Content>
    </Wrapper>
  );
};

export default BuildDetailsContractPanel;
