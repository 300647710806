export const URL_LOGIN = '/login';

export const URL_BACKEND = '/b';
export const URL_MY_PROFILE = `${URL_BACKEND}/me`;

export const URL_CONTACT_COMPANY_LIST = `${URL_BACKEND}/contact/:type`;
export const URL_CONTACT_COMPANY_DETAILS = `${URL_BACKEND}/contactCompany/:id`;

export const URL_BUILD_STYLES = `${URL_BACKEND}/buildStyle`;
export const URL_BUILD_STYLE_DETAILS = `${URL_BACKEND}/buildStyle/:id`;
export const URL_HOUSE_AREAS = `${URL_BACKEND}/houseArea`;
export const URL_BUILDS = `${URL_BACKEND}/build`;
export const URL_BUILD_DETAILS = `${URL_BACKEND}/build/:id`;
export const URL_HOUSE_STOREY = `${URL_BACKEND}/houseStorey`;

export const URL_SUBURBS = `${URL_BACKEND}/suburb`;
export const URL_CITY_COUNCILS = `${URL_BACKEND}/cityCouncil`;
export const URL_STATE = `${URL_BACKEND}/state`;
export const URL_ESTATE = `${URL_BACKEND}/estate`;
export const URL_GAS_TYPES = `${URL_BACKEND}/gasType`;

export const URL_SETTINGS = `${URL_BACKEND}/settings`;
export const URL_SETTINGS_COMPANY_PROFILE = `${URL_SETTINGS}/company`;
export const URL_SETTINGS_USERS = `${URL_SETTINGS}/users`;
export const URL_SETTINGS_BANKS = `${URL_SETTINGS}/banks`;
export const URL_SETTINGS_TAX_RATES = `${URL_SETTINGS}/taxRates`;
export const URL_SETTINGS_FINANCE_TYPE = `${URL_SETTINGS}/financeType`;
export const URL_SETTINGS_PLAN_REVISION = `${URL_SETTINGS}/planRevision`;
export const URL_SETTINGS_BUILD_NOTE_CATEGORY = `${URL_SETTINGS}/buildNoteCategory`;
export const URL_SETTINGS_BUILD_SUSPENSION_TYPE = `${URL_SETTINGS}/buildSuspensionType`;
export const URL_SETTINGS_BUILD_SUSPENSION_REASON = `${URL_SETTINGS}/buildSuspensionReason`;
