import ProtectedPage from '../../layouts/ProtectedPage';
import BuildList from '../../components/build/BuildList';

const BuildsPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Build Jobs`,
      }}
    >
      {(headerProps) => <BuildList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default BuildsPage;
