import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import BuildStyleService from '../../services/build/BuildStyleService';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import iBuildStyle from '../../types/build/iBuildStyle';
import BuildStyleDetailsPanel from '../../components/buildStyle/BuildStyleDetailsPanel';
import { URL_BUILD_STYLES } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';

const BuildStylesPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [savedStyle, setSavedStyle] = useState<iBuildStyle | null>(null);
  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iBuildStyle>
        updatedModel={savedStyle}
        emptyStateProps={{
          description: `Couldn't find requested Style with id: ${idStr}.`,
        }}
        getDataFn={() =>
          BuildStyleService.get(idStr, {
            include: 'CreatedBy,UpdatedBy',
          })
        }
      >
        {(buildStyle, stateBeforeReload) => (
          <BuildStyleDetailsPanel
            onSaved={(saved) => setSavedStyle(saved)}
            stateBeforeReload={stateBeforeReload}
            headerProps={{
              children: `${buildStyle.name}`,
              breadcrumbItems: [
                {
                  text: `Styles`,
                  href: URL_BUILD_STYLES,
                },
                {
                  text: `${buildStyle.name}`,
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {buildStyle.name}
                    </InlineFlex>
                  ),
                },
              ],
            }}
            buildStyle={buildStyle}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default BuildStylesPage;
