import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns, iPopupModal } from '../common/PopupModal';
import FileUploader from './FileUploader';
import styled from 'styled-components';
import React from 'react';
import AttachmentService from '../../services/attachment/AttachmentService';
import Toaster from '../common/Toaster';
import {
  HEADER_NAME_ASSET_IS_PUBLIC,
  HEADER_NAME_ASSET_TYPE,
  iConfigParams,
} from '../../services/AppService';
import iAttachment from '../../types/asset/iAttachment';

export type iAttachmentUploadPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  entityName: string;
  entityId: string;
  assetType?: string;
  isAssetPublic?: boolean;
  onUploaded: (attachments: iAttachment[]) => void;
  modalProps?: Omit<iPopupModal, 'titleId' | 'isOpen' | 'children'>;
};

const Wrapper = styled.div`
  .uploader-wrapper {
    height: 14rem;
    .mask {
      height: 17.25rem;
    }
  }
  margin-bottom: 3rem;
`;
const AttachmentUploadPopupBtn = ({
  onUploaded,
  modalProps,
  entityName,
  entityId,
  assetType,
  isAssetPublic,
  ...props
}: iAttachmentUploadPopupBtn) => {
  const [isUploading, setIsUploading] = React.useState(false);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isUploading === true) {
      return;
    }
    setModelShowing(false);
  };

  const onFilesSelected = (
    files: File[],
    setModelShowing: iSetShowingModalFn,
  ) => {
    setIsUploading(true);
    Promise.all(
      files.map((file: File) => {
        const data = new FormData();
        data.append('entityName', entityName);
        data.append('entityId', entityId);

        const headers: iConfigParams = {};
        if (assetType && `${assetType || ''}`.trim() !== '') {
          headers[HEADER_NAME_ASSET_TYPE] = `${assetType || ''}`.trim();
        }
        if (isAssetPublic === true) {
          headers[HEADER_NAME_ASSET_IS_PUBLIC] = 'true';
        }
        data.append('file', file);
        return AttachmentService.create(data, { headers });
      }),
    )
      .then((resp) => {
        setIsUploading(false);
        setModelShowing(false);
        onUploaded(resp);
      })
      .catch((err) => {
        setIsUploading(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttachmentUploadPopupBtn'}
      modalProps={(setModelShowing) => ({
        width: '1000px',
        title:
          isUploading === true
            ? `Don't close this window until it's finished`
            : `Uploading new assets`,
        shouldScrollInViewport: true,
        ...modalProps,
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            btnText: 'Done',
            isLoading: isUploading,
            appearance: 'primary',
            iconBefore: () => null,
            onClick: () => handleClose(setModelShowing),
          },
        }),
        body: (
          <Wrapper>
            <FileUploader
              isLoading={isUploading}
              className={'uploader-wrapper'}
              onFilesSelected={(files) =>
                onFilesSelected(files, setModelShowing)
              }
            />
          </Wrapper>
        ),
      })}
    />
  );
};

export default AttachmentUploadPopupBtn;
