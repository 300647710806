import iContactCompany from '../../types/contact/iContactCompany';
import styled from 'styled-components';
import React, { useState } from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import InlineEdit from '../frameWork/InlineEdit';
import { iConfigParams } from '../../services/AppService';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import Flex from '../frameWork/Flex';
import { Label } from '../frameWork/Form';
import ModifyBy from '../common/ModifyBy';
import tokens from '../frameWork/Tokens';
import AddressInlineEdit from '../address/AddressInlineEdit';
import InlineTextArea from '../frameWork/InlineTextArea';
import Tabs from '../frameWork/Tabs';

export type iContactCompanyDetailsPanel = iComponentWithPageHeader & {
  contactCompany: iContactCompany;
  onSaved?: (saved: iContactCompany) => void;
  testId?: string;
};

const Wrapper = styled.div`
  .details-col {
    max-width: 100%;
    width: 300px;
  }

  form > div {
    margin-block-start: 0;
  }

  label {
    margin-block-start: ${tokens('space.100', '8px')};
  }

  .comment-div {
    width: 620px;
    max-width: 100%;
  }

  .address-div {
    width: 820px;

    .address-col {
      max-width: 100%;
      width: 400px;
    }
  }

  .modified-div {
    width: 460px;
    max-width: 100%;

    .details-col {
      width: 300px;
    }
  }

  .activities {
    margin-top: ${tokens('space.1000', '5rem')};
    min-height: 10rem;
  }
`;

const ContactCompanyDetailsPanel = ({
  contactCompany,
  onSaved,
  headerProps,
  testId,
}: iContactCompanyDetailsPanel) => {
  const testIdStr = `contactCompanyDetails-${testId || ''}`;
  const [isSaving, setIsSaving] = useState(false);
  const typeName = ContactCompanyService.getHumanReadableType(
    contactCompany.type || '',
  );

  const handleUpdated = (newDataMap: iConfigParams) => {
    const filteredMap: iConfigParams = Object.keys(newDataMap)
      .filter(
        (key) =>
          newDataMap[key] !== undefined &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          newDataMap[key] !== contactCompany[key],
      )
      .reduce((map, key) => {
        return {
          ...map,
          [key]: newDataMap[key],
        };
      }, {});
    if (Object.keys(filteredMap).length <= 0) {
      return;
    }

    setIsSaving(true);
    ContactCompanyService.update(contactCompany.id, filteredMap)
      .then((resp) => {
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getCompanyDetails = () => {
    if (!contactCompany.isCompany) {
      return null;
    }

    return (
      <>
        {[
          {
            key: 'abn',
            label: 'A.B.N.',
            value: `${contactCompany.abn || ''}`.trim(),
            placeholder: `The ABN of this ${typeName}`,
          },
          {
            key: 'acn',
            label: 'ACN',
            value: `${contactCompany.acn || ''}`.trim(),
            placeholder: `The ACN of this ${typeName}`,
          },
        ].map((item) => {
          return (
            <div key={item.key} className={'details-col'}>
              <InlineEdit
                readViewFitContainerWidth
                isDisabled={isSaving}
                testId={item.key}
                label={item.label}
                placeHolder={item.placeholder}
                value={item.value}
                onConfirm={(value) => handleUpdated({ [item.key]: value })}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <Wrapper data-testid={testIdStr}>
        <Flex className={'gap-1 flex-wrap'}>
          {[
            {
              key: 'name',
              label: 'Name',
              isRequired: true,
              value: contactCompany.name,
              placeholder: `The name of this ${typeName}`,
            },
            {
              key: 'email',
              label: 'Email',
              value: `${contactCompany.email || ''}`.trim(),
              placeholder: `The email of this ${typeName}`,
            },
            {
              key: 'contactNumber',
              label: 'Contact Number',
              value: `${contactCompany.contactNumber || ''}`.trim(),
              placeholder: `The phone number or mobile number`,
            },
          ].map((item) => {
            return (
              <div key={item.key} className={'details-col'}>
                <InlineEdit
                  readViewFitContainerWidth
                  isDisabled={isSaving}
                  testId={item.key}
                  label={item.label}
                  isRequired={item.isRequired}
                  placeHolder={item.placeholder}
                  value={item.value}
                  onConfirm={(value) => handleUpdated({ [item.key]: value })}
                />
              </div>
            );
          })}
          <Flex className={'gap-1 modified-div'}>
            <div className={'details-col'}>
              <Label htmlFor={''}>Created</Label>
              <small>
                <ModifyBy
                  by={contactCompany.CreatedBy}
                  at={contactCompany.createdAt}
                />
              </small>
            </div>

            <div className={'details-col'}>
              <Label htmlFor={''}>Updated</Label>
              <small>
                <ModifyBy
                  by={contactCompany.UpdatedBy}
                  at={contactCompany.updatedAt}
                />
              </small>
            </div>
          </Flex>
        </Flex>

        <Flex className={'gap-1 flex-wrap'}>
          <div>
            <Toggle
              separatedLines
              testId={'isCompany'}
              label={`Is a company?`}
              isChecked={contactCompany.isCompany === true}
              onChange={() =>
                handleUpdated({
                  isCompany: contactCompany.isCompany === true ? false : true,
                })
              }
            />
          </div>
          {getCompanyDetails()}
        </Flex>

        <Flex className={'gap-1 flex-wrap'}>
          <div className={'comment-div'}>
            <InlineTextArea
              editViewProps={{
                maxHeight: '10rem',
              }}
              readViewStyle={{
                maxHeight: '10rem',
                minHeight: '10rem',
                overflowY: 'auto',
              }}
              readViewFitContainerWidth
              isDisabled={isSaving}
              testId={'comments'}
              label={'Comments'}
              placeHolder={`Some comments for this ${typeName}`}
              value={contactCompany.comments || ''}
              onConfirm={(value) => handleUpdated({ comments: value })}
            />
          </div>
          <Flex className={'gap-1 flex-wrap address-div'}>
            <div className={'address-col'}>
              <AddressInlineEdit
                label={'Address'}
                testId={'address'}
                onSaved={(saved) => handleUpdated({ addressId: saved.id })}
                address={contactCompany.Address}
              />
            </div>
            <div className={'address-col'}>
              <AddressInlineEdit
                label={'Postal Address'}
                testId={'postalAddress'}
                onSaved={(saved) =>
                  handleUpdated({ postalAddressId: saved.id })
                }
                address={contactCompany.PostalAddress}
              />
            </div>
          </Flex>
        </Flex>

        <Tabs
          className={'activities'}
          tabs={[
            {
              eventKey: 'builds',
              title: 'Builds',
              tabContent: <div>All builds</div>,
            },
            {
              eventKey: 'history',
              title: 'History',
              tabContent: <div>All history logs</div>,
            },
          ]}
        />
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ContactCompanyDetailsPanel;
