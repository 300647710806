import { ReactNode } from 'react';
import Icons from '../../../frameWork/Icons';

export enum BuildDetailsContractKeys {
  OVERVIEW = 'Overview',
  HOUSE = 'House',
  DOORS = 'Doors',
  WINDOWS = 'Windows',
  ELECTRICAL = 'Electrical',
  CABINETS = 'Cabinets',
  FLOORING = 'Flooring',
  CONCRETE = 'Concrete',
  PACKS = 'Packs',
  FENCING = 'Fencing',
  BATHROOMS = 'Bathrooms',
  NICHE = 'Niche',
  WALL_TILES = 'Wall Tiles',
  PIERS = 'Piers',
  APPLIANCES = 'Appliances',
  WATER_TANK = 'Water Tank',
  PLUMBING = 'Plumbing',
}

const buildDetailsContractMenuItemsMap: {
  [key: string]: { name: string; icon: ReactNode; component: ReactNode };
} = {
  [BuildDetailsContractKeys.OVERVIEW]: {
    name: BuildDetailsContractKeys.OVERVIEW,
    icon: <Icons.OverviewIcon label={BuildDetailsContractKeys.OVERVIEW} />,
    component: <div>Overview</div>,
  },
  [BuildDetailsContractKeys.HOUSE]: {
    name: BuildDetailsContractKeys.HOUSE,
    icon: <Icons.HomeIcon label={BuildDetailsContractKeys.HOUSE} />,
    component: <div>House</div>,
  },
  [BuildDetailsContractKeys.DOORS]: {
    name: BuildDetailsContractKeys.DOORS,
    icon: <Icons.RoomMenuIcon label={BuildDetailsContractKeys.DOORS} />,
    component: <div>Doors</div>,
  },
  [BuildDetailsContractKeys.DOORS]: {
    name: BuildDetailsContractKeys.DOORS,
    icon: <Icons.DoorsIcon label={BuildDetailsContractKeys.DOORS} />,
    component: <div>Doors</div>,
  },
  [BuildDetailsContractKeys.WINDOWS]: {
    name: BuildDetailsContractKeys.WINDOWS,
    icon: (
      <Icons.EditorLayoutThreeWithSidebarsIcon
        label={BuildDetailsContractKeys.WINDOWS}
      />
    ),
    component: <div>Windows</div>,
  },
  [BuildDetailsContractKeys.ELECTRICAL]: {
    name: BuildDetailsContractKeys.ELECTRICAL,
    icon: <Icons.EditorHintIcon label={BuildDetailsContractKeys.ELECTRICAL} />,
    component: <div>Electrical</div>,
  },
  [BuildDetailsContractKeys.CABINETS]: {
    name: BuildDetailsContractKeys.CABINETS,
    icon: <Icons.QueuesIcon label={BuildDetailsContractKeys.CABINETS} />,
    component: <div>Cabinets</div>,
  },
  [BuildDetailsContractKeys.FLOORING]: {
    name: BuildDetailsContractKeys.FLOORING,
    icon: (
      <Icons.BitbucketSourceIcon label={BuildDetailsContractKeys.FLOORING} />
    ),
    component: <div>Flooring</div>,
  },
  [BuildDetailsContractKeys.CONCRETE]: {
    name: BuildDetailsContractKeys.CONCRETE,
    icon: (
      <Icons.EditorUnderlineIcon label={BuildDetailsContractKeys.CONCRETE} />
    ),
    component: <div>Concrete</div>,
  },
  [BuildDetailsContractKeys.PACKS]: {
    name: BuildDetailsContractKeys.PACKS,
    icon: <Icons.EditorMediaWideIcon label={BuildDetailsContractKeys.PACKS} />,
    component: <div>Packs</div>,
  },
  [BuildDetailsContractKeys.FENCING]: {
    name: BuildDetailsContractKeys.FENCING,
    icon: <Icons.BoardIcon label={BuildDetailsContractKeys.FENCING} />,
    component: <div>Fencing</div>,
  },
  [BuildDetailsContractKeys.BATHROOMS]: {
    name: BuildDetailsContractKeys.BATHROOMS,
    icon: <Icons.BathIcon label={BuildDetailsContractKeys.BATHROOMS} />,
    component: <div>Bathrooms</div>,
  },
  [BuildDetailsContractKeys.NICHE]: {
    name: BuildDetailsContractKeys.NICHE,
    icon: (
      <Icons.MediaServicesFilterIcon label={BuildDetailsContractKeys.NICHE} />
    ),
    component: <div>Niche</div>,
  },
  [BuildDetailsContractKeys.WALL_TILES]: {
    name: BuildDetailsContractKeys.WALL_TILES,
    icon: <Icons.AppSwitcherIcon label={BuildDetailsContractKeys.WALL_TILES} />,
    component: <div>Wall Tiles</div>,
  },
  [BuildDetailsContractKeys.PIERS]: {
    name: BuildDetailsContractKeys.PIERS,
    icon: <Icons.PiersIcon label={BuildDetailsContractKeys.PIERS} />,
    component: <div>Piers</div>,
  },
  [BuildDetailsContractKeys.APPLIANCES]: {
    name: BuildDetailsContractKeys.APPLIANCES,
    icon: <Icons.AppliancesIcon label={BuildDetailsContractKeys.APPLIANCES} />,
    component: <div>Appliances</div>,
  },
  [BuildDetailsContractKeys.WATER_TANK]: {
    name: BuildDetailsContractKeys.WATER_TANK,
    icon: <Icons.WaterTankIcon label={BuildDetailsContractKeys.WATER_TANK} />,
    component: <div>Water Tank</div>,
  },
  [BuildDetailsContractKeys.PLUMBING]: {
    name: BuildDetailsContractKeys.PLUMBING,
    icon: (
      <Icons.BitbucketCompareIcon label={BuildDetailsContractKeys.PLUMBING} />
    ),
    component: <div>Plumbing</div>,
  },
};

const BuildDetailsContractHelper = {
  buildDetailsContractMenuItemsMap,
};
export default BuildDetailsContractHelper;
