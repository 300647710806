import TextField from '../frameWork/TextField';
import styled from 'styled-components';
import { getErrorMsgStr, hasError, iErrorMap } from '../form/FormError';
import iBaseType from '../../types/iBaseType';
import React from 'react';

const Wrapper = styled.div`
  .field-wrapper {
    margin-bottom: 0.4rem;
  }
`;

export type iUseAsForm = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFieldChange: (fieldName: string, newValue: any) => void;
  errorMap: iErrorMap;
};

type iEntityFormFieldErrorProps = {
  errorMsg?: string;
  isInvalid?: boolean;
};
export type iEntityFormField = {
  fieldName: string;
  label: string;
  isDisabled?: boolean;
  value: string | number;
  isRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isValid?: (value: any) => { isValid: boolean; errMessages: string[] };
  testId?: string;
  renderComponent?: (
    props: iEntityFormField,
    useAsForm: iUseAsForm,
    errorProps: iEntityFormFieldErrorProps,
  ) => React.ReactNode;
};

type iEntityEditPanel<T extends iBaseType> = {
  entity?: T | null;
  className?: string;
  testId?: string;
  isDisabled?: boolean;
  getFormFields: (data: {
    entity?: T | null;
    isDisabled?: boolean;
  }) => iEntityFormField[];
  useAsForm: iUseAsForm;
};

const EntityEditPanel = <T extends iBaseType>({
  entity,
  className,
  testId,
  useAsForm,
  getFormFields,
  isDisabled = false,
}: iEntityEditPanel<T>) => {
  return (
    <Wrapper
      className={`entity-edit-panel ${className || ''}`}
      data-testid={testId}
    >
      {getFormFields({ entity, isDisabled }).map((row: iEntityFormField) => {
        return (
          <div
            className={`field-wrapper ${row.fieldName || ''}`}
            key={row.fieldName}
          >
            {row.renderComponent ? (
              row.renderComponent(row, useAsForm, {
                errorMsg: getErrorMsgStr({
                  error: useAsForm.errorMap,
                  fieldName: row.fieldName,
                }),
                isInvalid: hasError({
                  error: useAsForm.errorMap,
                  fieldName: row.fieldName,
                }),
              })
            ) : (
              <TextField
                testId={row.testId || ''}
                isRequired={row.isRequired}
                isDisabled={row.isDisabled}
                label={row.label}
                value={row.value}
                placeholder={row.label}
                isInvalid={hasError({
                  error: useAsForm.errorMap,
                  fieldName: row.fieldName,
                })}
                errorMsg={getErrorMsgStr({
                  error: useAsForm.errorMap,
                  fieldName: row.fieldName,
                })}
                onChange={(event) =>
                  useAsForm.onFieldChange(
                    row.fieldName,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    event.target.value || '',
                  )
                }
              />
            )}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default EntityEditPanel;
