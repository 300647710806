import { default as OriginPageHeader } from '@atlaskit/page-header';
import type { ReactElement, ReactNode } from 'react';

import Breadcrumbs, {
  __noop,
  BreadcrumbsItem,
  BreadcrumbsItemProps,
} from './Breadcrumbs';
import styled from 'styled-components';

type PageHeaderProps = {
  actions?: ReactElement;
  /**
   * Contents of the action bar to be rendered next to the page title. Typically a button group.
   */
  bottomBar?: ReactElement;
  /**
   * Page breadcrumbs to be rendered above the title.
   */
  breadcrumbs?: ReactElement;
  /**
   * Contents of the bottom bar to be rendered below the page title. Typically contains a search bar and/or filters.
   */
  children?: ReactNode;
  /**
   * Content of the page title. The text wraps by default.
   */
  disableTitleStyles?: boolean;
  /**
   * Returns the inner ref to the DOM element of the title. This is exposed so the focus can be set.
   */
  innerRef?: (element: HTMLElement) => void;
  /**
   * Prevent the title from wrapping across lines. Avoid using this wherever possible, as truncation can make page headings inaccessible.
   */
  truncateTitle?: boolean;
  /**
   * Used as the ID of the inner h1 tag. This is exposed so the header text can be used as label of other elements by aria-labelledby.
   */
  id?: string;
};

export type iPageHeader = PageHeaderProps & {
  breadcrumbItems?: BreadcrumbsItemProps[];
  testId?: string;
};

const Wrapper = styled.div`
  > div {
    > div {
      align-items: center;
    }
  }
`;

const PageHeader = ({
  breadcrumbs,
  breadcrumbItems,
  testId,
  ...props
}: iPageHeader) => {
  const getBreadcrumbs = () => {
    if (breadcrumbs) {
      return breadcrumbs;
    }
    const items = breadcrumbItems || [];
    if (items.length > 0) {
      return (
        <Breadcrumbs onExpand={__noop} testId={`${testId}-breadcrumbItems`}>
          {items.map((itemProps, index) => (
            <BreadcrumbsItem {...itemProps} key={index} />
          ))}
        </Breadcrumbs>
      );
    }
    return undefined;
  };

  return (
    <Wrapper data-testid={testId} className={'page-header'}>
      <OriginPageHeader {...props} breadcrumbs={getBreadcrumbs()} />
    </Wrapper>
  );
};

export default PageHeader;
