import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
  ModalHeaderProps,
  ModalFooterProps,
  ModalTitleProps,
  ModalBodyProps,
  ModalAttributes,
} from '@atlaskit/modal-dialog';
import Button, { ButtonProps, IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import Flex from '../frameWork/Flex';
import React from 'react';

export type iPopupModal = Omit<ModalAttributes, 'children'> & {
  isOpen?: boolean;
  showCloseBtn?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
  headerProps?: ModalHeaderProps;
  footerProps?: ModalFooterProps;
  titleProps?: ModalTitleProps;
  bodyProps?: ModalBodyProps;
};

const PopupModal = (props: iPopupModal) => {
  const testId = `PopupModal`;
  const {
    isOpen = false,
    showCloseBtn = true,
    footer,
    header,
    headerProps,
    footerProps,
    titleProps,
    bodyProps,
    title,
    body,
    ...modalProps
  } = props;
  const getHeader = () => {
    if (!header && !title) {
      return null;
    }

    return (
      <ModalHeader {...headerProps}>
        {header ? (
          header
        ) : (
          <Flex
            className={'align-items-start justify-content-between full-width'}
          >
            <ModalTitle {...titleProps}>{title}</ModalTitle>
            {showCloseBtn && (
              <IconButton
                testId={`${testId}-header-close-btn`}
                appearance="subtle"
                icon={Icons.CrossIcon}
                label="Close Modal"
                onClick={(event, analyticsEvent) =>
                  modalProps.onClose &&
                  modalProps.onClose(event, analyticsEvent)
                }
              />
            )}
          </Flex>
        )}
      </ModalHeader>
    );
  };

  const getBody = () => {
    if (!body) {
      return null;
    }
    return <ModalBody {...bodyProps}>{body}</ModalBody>;
  };

  const getFooter = () => {
    if (!footer) {
      return null;
    }
    return <ModalFooter {...footerProps}>{footer}</ModalFooter>;
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          testId={testId}
          shouldCloseOnEscapePress
          autoFocus={false}
          {...modalProps}
        >
          {getHeader()}
          {getBody()}
          {getFooter()}
        </Modal>
      )}
    </ModalTransition>
  );
};

export type iGetDefaultBtn = Omit<ButtonProps, 'children'> & {
  btnText?: string;
};
export const getDefaultBtn = ({ btnText, ...btnProps }: iGetDefaultBtn) => {
  return (
    <Button appearance="subtle" {...btnProps}>
      {btnText || ''}
    </Button>
  );
};

type iGetFooterWithBtns = {
  actionBtn?: React.ReactNode;
  cancelBtn?: React.ReactNode;
  cancelBtnProps?: iGetDefaultBtn;
  actionBtnProps?: iGetDefaultBtn;
};
export const getFooterWithBtns = ({
  actionBtn,
  actionBtnProps,
  cancelBtn,
  cancelBtnProps,
}: iGetFooterWithBtns) => {
  const getActionBtn = () => {
    if (actionBtn) {
      return actionBtn;
    }

    if (!actionBtnProps) {
      return null;
    }

    return getDefaultBtn({
      btnText: 'Send',
      appearance: 'primary',
      ...actionBtnProps,
    });
  };

  return (
    <>
      {cancelBtn ||
        getDefaultBtn({
          iconBefore: Icons.CrossIcon,
          btnText: 'Cancel',
          ...cancelBtnProps,
        })}

      {getActionBtn()}
    </>
  );
};

export default PopupModal;
