import React from 'react';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { IconButton, IconButtonProps } from '../frameWork/Button';
import Icons from '../frameWork/Icons';

export type iPageTitleWithCreateBtn = {
  testId?: string;
  className?: string;
  title: React.ReactNode;
  createBtn?: React.ReactNode;
  createBtnProps?: IconButtonProps;
};

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.200')};
  align-items: center !important;

  .create-btn-wrapper {
    font-size: ${Tokens('font.size.200')};
    button {
      height: 1.7rem;
      width: 1.7rem;
    }
  }
`;

export const getCreateIconBtn = (
  props?: Omit<IconButtonProps, 'icon' | 'label'> &
    Partial<Pick<IconButtonProps, 'icon' | 'label'>>,
) => {
  return (
    <IconButton
      icon={(iconProps) => <Icons.AddIcon {...iconProps} size="small" />}
      label={'Create'}
      spacing={'compact'}
      isSelected
      {...props}
    />
  );
};

const PageTitleWithCreateBtn = ({
  className,
  testId,
  title,
  createBtn,
  createBtnProps,
}: iPageTitleWithCreateBtn) => {
  return (
    <Wrapper
      className={`page-title-with-create ${className || ''}`}
      data-testid={`PageTitleWithCreateBtn-${testId || ''}`}
    >
      <div data-testid={`PageTitleWithCreateBtn-${testId || ''}-title`}>
        {title}
      </div>
      <div
        className={'create-btn-wrapper'}
        data-testid={`PageTitleWithCreateBtn-${testId || ''}-btnWrapper`}
      >
        {createBtn || getCreateIconBtn(createBtnProps)}
      </div>
    </Wrapper>
  );
};

export default PageTitleWithCreateBtn;
