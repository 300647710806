import React from 'react';
import ProtectedPage from './ProtectedPage';
import SettingsSideBar from '../components/layout/SettingsSideBar';
import PageHeader, { iPageHeader } from '../components/frameWork/PageHeader';
import { InlineFlex } from '../components/frameWork/Flex';
import { URL_SETTINGS } from '../helpers/UrlMap';
import { BreadcrumbsItemProps } from '../components/frameWork/Breadcrumbs';

type iSettingsPage = {
  children:
    | React.ReactNode
    | ((pageHeaderProps: iPageHeader) => React.ReactNode);
  extraBreadcrumbs?: BreadcrumbsItemProps[];
  title: string;
};
const SettingsPage = ({
  children,
  title,
  extraBreadcrumbs = [],
}: iSettingsPage) => {
  const getHeaderProps = (): iPageHeader => {
    return {
      children: title,
      breadcrumbItems: [
        {
          text: 'Settings',
          href: URL_SETTINGS,
        },
        ...extraBreadcrumbs,
        {
          text: title,
          component: () => (
            <InlineFlex className={'align-items-center'}>{title}</InlineFlex>
          ),
        },
      ],
    };
  };

  const getChildren = () => {
    if (typeof children === 'function') {
      return children(getHeaderProps());
    }
    return (
      <>
        <PageHeader {...getHeaderProps()} />
        {children}
      </>
    );
  };

  return (
    <ProtectedPage
      leftSideBar={<SettingsSideBar />}
      leftSideBarProps={{ shouldPersistWidth: true }}
    >
      {getChildren()}
    </ProtectedPage>
  );
};

export default SettingsPage;
